<template>
    <v-container cols="12" md="8" lg="6" xl="4" style="align:center">
        <div>
            <v-card width="500" style="margin:auto">
                <v-card-title class="text-h5 grey lighten-2">
                    {{ d.menu }}
                </v-card-title>
                <v-card-text>
                    <v-row class="pt-4">
                        <v-col cols="6">
                            <v-btn class="menu">{{ d.events }}</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn class="menu">{{ d.places }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-btn class="menu">{{ d.activities }}</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn class="menu">{{ d.eatAndDrink }}</v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <v-btn class="menu">{{ d.sleep }}</v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn class="menu">{{ d.gallery }}</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        
        
            

        
    </v-container>
</template>

<script>
export default {
    name: 'Home',
	components: {
	},
	data () {
		return {
			dialog: true,
        }
    },
    computed: {
        d () {
			return this.$store.getters.lang_dict
		},
    }
}
</script>

